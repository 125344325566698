import { render, staticRenderFns } from "./EventRegister.vue?vue&type=template&id=097cee81&"
import script from "./EventRegister.controller.js?vue&type=script&lang=js&"
export * from "./EventRegister.controller.js?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./EventRegister.styles.scss?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "9b629b1e"
  
)

export default component.exports